import { useEffect, useRef, useState } from "react";
import { LoginWrapper } from "../Styles/Login-Style";
import VanillaTilt from "vanilla-tilt";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Create_User_URL, Login_User_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function Login() {

    const [showPassword, setShowPassword] = useState(false);
    const [showRPassword, setShowRPassword] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        password: '',
    });
    const [inputs, setInputs] = useState({
        name: '',
        phone: '',
        email: '',
        password: ''
    });

    const tiltRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (tiltRef.current) {
            VanillaTilt.init(tiltRef.current, {
            max: 15,
            speed: 400,
            glare: true,
            "max-glare": 0.5
            });
        }
        return () => {
            if (tiltRef.current?.vanillaTilt) {
            tiltRef.current.vanillaTilt.destroy();
            }
        };
    }, []);

    useEffect(() => {
        let label = document.querySelectorAll('.formInputLabel');

        label.forEach(label => {
            label.innerHTML = label.innerText.split('').map((letters, i) => `<span style="transition-delay: ${i * 50}ms;">${letters}</span>`).join('');
        })
    },[]);

    function handlePassword() {
        setShowPassword(!showPassword);
    }

    function handleRPassword() {
        setShowRPassword(!showRPassword);
    }

    const handleCredential = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    }

    const handleInput = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value});
    }

    const handleLoginRegister = () => {
        setToggler(!toggler);
    }

    function handleRegisterSubmit(e) {
        e.preventDefault();
        
        axios.post(Create_User_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data.status);
            let status = res.data.status;

            if(status == "200"){
                toast.success('Registration Successful !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setToggler(false);
                setInputs({name: '', phone: '', email: '', password: ''});
            }
        })
        .catch(err => {
            console.log(err.response);
            let status = err.response.data.status;

            if(status == "400"){
                toast.warn('This user is already exists !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "500"){
                toast.error('Internal Server Error', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
    }

    function handleLoginSubmit(e) {
        e.preventDefault();
        
        axios.post(Login_User_URL, credentials, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data);
            let status = res.data.status;

            if(status == "200"){
                toast.success('Authentication Successful !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setCredentials({name: '', password: ''});
                localStorage.setItem("LoggedIn", true);
                localStorage.setItem("UserId", res.data.userId);
                localStorage.setItem("User", res.data.user);
                navigate('/panel');
            }
        })
        .catch(err => {
            let status = err.response.data.status;
            console.log(status);

            if(status == "400"){
                toast.error('Invalid Credentials !!!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "403"){
                toast.warn('User Not Found !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
    }

    return(
        <>
            <LoginWrapper>
                <div className="login_inner">
                    <div className="heading"><h1>Theater <span>Admin</span></h1></div>
                    <div className={`form_box ${toggler?'active':''}`}>
                        {[...Array(5)].map((_, i) => (
                            <div key={i} className="square" style={{ '--i': i }}></div>
                        ))}
                        <div className="forms_inner" ref={tiltRef} data-tilt>
                            <form id="login" className={toggler ? 'active' : ''} onSubmit={handleLoginSubmit}>
                                <div className="form_head"><h3>Signin</h3></div>
                                <div className="form_input_sec">
                                    <div className="inputBox">
                                        <input type="text" name="name" value={credentials.name || ""} onChange={handleCredential}  required />
                                        <label className="formInputLabel">Username</label>
                                    </div>
                                    <div className="inputBox">
                                        <input type={showPassword ? 'text' : 'password'} name="password" value={credentials.password || ""} onChange={handleCredential}  required />
                                        <label className="formInputLabel">Password</label>
                                        <a onClick={handlePassword}><i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                                    </div>
                                    <div className="inputBox">
                                        <button>Sign In</button>
                                    </div>
                                    <div className="redirect">
                                        <p>Don't have any account? <a onClick={handleLoginRegister}>Signup</a></p>
                                    </div>
                                </div>
                            </form>
                            <form id="register" className={toggler ? 'active' : ''} onSubmit={handleRegisterSubmit}>
                                <div className="form_head"><h3>Signup</h3></div>
                                <div className="form_input_sec">
                                    <div className="inputBox">
                                        <input type="text" name="name" value={inputs.name || ""} onChange={handleInput} required />
                                        <label className="formInputLabel">Name</label>
                                    </div>
                                    <div className="inputBox">
                                        <input type="text" name="phone" value={inputs.phone || ""} onChange={handleInput} required />
                                        <label className="formInputLabel">Mobile</label>
                                    </div>
                                    <div className="inputBox">
                                        <input type="text" name="email" value={inputs.email || ""} onChange={handleInput} required />
                                        <label className="formInputLabel">Email</label>
                                    </div>
                                    <div className="inputBox">
                                        <input type={showRPassword ? 'text' : 'password'} name="password" value={inputs.password || ""}  onChange={handleInput} required />
                                        <label className="formInputLabel">Password</label>
                                        <a onClick={handleRPassword}><i className={`fa-solid ${showRPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i></a>
                                    </div>
                                    <div className="inputBox">
                                        <button type="submit">Register</button>
                                    </div>
                                    <div className="redirect">
                                        <p>Already have an account? <a onClick={handleLoginRegister}>Signin</a></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </LoginWrapper>
        </>
    );
}


export default Login;