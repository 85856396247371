// export const Base_URL = "http://localhost/Ticket-Counter-Backend/TheaterAdmin-API";
export const Base_URL = "https://ticketbay.in/Backend/TheaterAdmin-API";


// Create User
export const Create_User_URL = `${Base_URL}/user-register.php`;

// Login User
export const Login_User_URL = `${Base_URL}/user-login.php`;

// Screen Layout Upload
export const Screen_Upload_URL = `${Base_URL}/screen-request.php`;

// Theater Request
export const Theater_Req_URL = `${Base_URL}/theater-request.php`;

// Request Status
export const Req_Status_URL = `${Base_URL}/req-status-fetch.php`;

// Fetch Movies
export const Fetch_Movies_URL = `${Base_URL}/fetch-movie.php`;

// Fetch Screens
export const Fetch_Screens_URL = `${Base_URL}/screen-data-fetch.php`;

// Fetch Screens Shows
export const Fetch_Screen_Shows_URL = `${Base_URL}/screen-shows-fetch.php`;

// Today Screens Shows
export const Today_Screen_Shows_URL = `${Base_URL}/today-screen-shows-fetch.php`;

// Fetch Upcoming Shows
export const Upcoming_Screen_Shows_URL = `${Base_URL}/upcoming-screen-shows-fetch.php`;

// Add Price
export const Add_Section_Price_URL = `${Base_URL}/add-price.php`;

// Publish Screen
export const Publish_SCreen_URL = `${Base_URL}/publish-screen.php`;

// Fetch seat layout
export const Fetch_Seats_URL = `${Base_URL}/fetch-seat-layout.php`;

// Fetch Booked seat
export const Fetch_Booked_Seats_URL = `${Base_URL}/fetch-booked-seats.php`;

// Fetch active screens
export const Fetch_Active_Screens_URL = `${Base_URL}/fetch-active-screens.php`;

// Add Screen Show
export const Add_Screens_Shows_URL = `${Base_URL}/add-theater-show.php`;

// Delete Screen Show
export const Delete_Screen_Shows_URL = `${Base_URL}/delete-added-movie.php`;

// Fetch Added Shows
export const Fetch_Added_Shows_URL = `${Base_URL}/fetch-selected-movies.php`;

// Fetch Today's Shows
export const Fetch_Today_Shows_URL = `${Base_URL}/fetch-today-shows.php`;

// Request Movies
export const Request_Movies_URL = `${Base_URL}/request-movies.php`;

// Book seats
export const Book_Seats_URL = `${Base_URL}/seat-book.php`;

// Block seats
export const Block_Seats_URL = `${Base_URL}/seat-block.php`;

// Fetch Movies Date-wise
export const Fetch_Movies_Datewise_URL = `${Base_URL}/fetch-movies-datewise.php`;

// Fetch Transaction Report
export const Transaction_Report_URL = `${Base_URL}/transaction-report.php`;

// Transaction Report Download
export const Transaction_Report_Download_URL = `${Base_URL}/report-download.php`;