import { styled } from "@mui/material";


export const TransactionReportWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding-top: 10px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                color: #444;
            }

            button {
                position: relative;
                width: 180px;
                height: 55px;
                font-size: 17px;
                font-weight: 500;
                border: none;
                outline: none;
                border-radius: 6px;
                background: #3FE44F;
                color: #FFF;
                cursor: pointer;

                i {
                    position: relative;
                    margin-right: 8px;
                }
            }
        }

        .filter_sec {
            position: relative;
            margin-top: 35px;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #fff, #E1E0EA, #fff);
            }

            .sec_inner {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .date_box {
                    position: relative;
                    width: 30%;
                    margin-bottom: 25px;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        outline: none;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        font-size: 16px;
                        color: #555;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        opacity: 0;
                        pointer-events: none;
                    }

                    input:focus,
                    input:valid {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        opacity: 1;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .selectBox {
                    position: relative;
                    width: 20%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    input.search_input {
                        display: flex;
                    }

                    &.movie {
                        width: 39%;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 450px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }

                .download_sec {
                    position: relative;
                    width: 46%;
                    margin-bottom: 25px;
                    display: flex;
                    justify-content: flex-end;

                    button {
                        position: relative;
                        padding: 0px 40px;
                        height: 53px;
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        border: 2px solid #02C0FF;
                        border-radius: 8px;
                        background: #02C0FF;
                        color: #FFF;
                        font-weight: 500;
                        cursor: pointer;
                        transition: all 0.5s;

                        &.disable {
                            pointer-events: none;
                        }

                        i {
                            position: relative;
                            margin-right: 8px;
                        }

                        &:hover {
                            background: #FFF;
                            color: #02C0FF;
                            transition: all 0.5s;
                        }

                        svg {
                            position: relative;
                            margin-left: 10px;
                            width: 30px;
                            height: 30px;
                        }

                        .loader_circle_1{
                            animation: rotateClockwise 1.5s linear infinite;
                            transform-origin: center;
                            transform-box: fill-box;
                        }

                        @keyframes rotateClockwise {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }
                    }
                }
            }
        }

        .transaction_data_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            padding-bottom: 35px;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #E1E0EA, #fff);
            }

            &:last-child:after {
                display: none;
            }

            .movie_details {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                h2 {
                    position: relative;
                    display: flex;
                    font-family: "SUSE", sans-serif;
                    font-size: 23px;
                    font-weight: 600;
                    color: #000;

                    span {
                        position: relative;
                        margin-left: 8px;
                        font-size: 22px;
                        color: #555;
                        font-family: "SUSE", sans-serif;
                    }
                }

                ul {
                    position: relative;
                    margin-top: 10px;
                    display: flex;

                    li {
                        position: relative;
                        list-style: none;
                        display: flex;

                        &:first-child {
                            margin-right: 50px;
                        }

                        span {
                            position: relative;
                            font-weight: 500;
                            color: #111;
                            margin-right: 8px;
                        }

                        p {
                            position: relative;
                            color: rgb(113, 113, 113);
                        }
                    }
                }
            }

            .table_sec {
                position: relative;
                width: 100%;
                margin-top: 20px;

                table {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    thead {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #FC6736;
                        background: #FC6736;
                        border-radius: 10px 10px 0 0;

                        tr {
                            position: relative;
                            width: 100%;
                            height: 50px;
                            display: flex;

                            &:first-child  {
                                border-bottom: 1px solid #FFF;

                                th {
                                    position: relative;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 14px;
                                    color: #FFF;
                                    font-weight: 600;
                                    border-right: 1px solid #FFF;
                                    width: 28%;
                                    padding: 0px 25px;
                                    justify-content: center;

                                    &:first-child {
                                        width: 16%;
                                        padding: 0px 25px;
                                        justify-content: flex-start;
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }

                            &:last-child {
                                
                                th {
                                    position: relative;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 14px;
                                    color: #FFF;
                                    font-weight: 600;
                                    border-right: 1px solid #FFF;
                                    width: 14%;
                                    padding: 0px 25px;
                                    justify-content: center;

                                    &:first-child {
                                        width: 16%;
                                        padding: 0px 25px;
                                        justify-content: flex-start;
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }

                    tbody {
                        position: relative;
                        width: 100%;
                        border: 1px solid #E1E0EA;
                        border-top: none;
                        border-radius: 0 0 10px 10px;
                        display: flex;
                        flex-direction: column;
                        overflow-y: auto;
                        scrollbar-width: none;

                        tr {
                            position: relative;
                            width: 100%;
                            min-height: 55px;
                            display: flex;
                            border-bottom: 1px solid #E1E0EA;

                            &:last-of-type {
                                border-bottom: none;
                            }

                            &:nth-of-type(even) {
                                background: rgba(2, 192, 255, 0.15);
                            }

                            td {
                                position: relative;
                                width: 14%;
                                padding: 15px 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 15px;
                                color: #444;
                                font-weight: 500;
                                border-right: 1px solid #E1E0EA;

                                &:first-child {
                                    width: 16%;
                                    justify-content: flex-start;
                                }

                                &:last-child {
                                    border-right: none;
                                }
                            }

                            p {
                                position: relative;
                                width: 100%;
                                padding: 15px 25px;
                                display: flex;
                                justify-content: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }
        }

        .no_data_msg {
            position: relative;
            margin-top: 35px;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 23px;
                color: rgb(200, 200, 200);
            }
        }
    }
`;

export const BookingReportWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 10px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                color: #444;
            }

            button {
                position: relative;
                width: 180px;
                height: 55px;
                font-size: 17px;
                font-weight: 500;
                border: none;
                outline: none;
                border-radius: 6px;
                background: #3FE44F;
                color: #FFF;
                cursor: pointer;

                i {
                    position: relative;
                    margin-right: 8px;
                }
            }
        }

        .filter_sec {
            position: relative;
            margin-top: 35px;
            width: 100%;

            .sec_inner {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .date_box {
                    position: relative;
                    width: 30%;
                    margin-bottom: 25px;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        outline: none;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        font-size: 16px;
                        color: #555;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        opacity: 0;
                        pointer-events: none;
                    }

                    input:focus,
                    input:valid {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        opacity: 1;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .selectBox {
                    position: relative;
                    width: 20%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    input.search_input {
                        display: flex;
                    }

                    &.movie {
                        width: 39%;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 450px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }

                .movieSelectBox {
                    position: relative;
                    width: 46%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        .report_sec {
            position: relative;
            width: 100%;
            height: calc(100% - 150px);
        }
    }
`;

export const SeatReportWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 25px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #fff, #E1E0EA, #fff);
    }

    .layout_inner {
        position: relative;
        width: 100%;
        height: 100%;

        .inner_content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .seats_layout {
                position: relative;
                width: 100%;
                height: calc(100% - 30px);
                padding-bottom: 30px;

                .scroll_sec {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    scrollbar-width: none;

                    .swiper {
                        position: relative;
                        width: 100%;

                        .swiper-slide {
                            position: relative;
                            width: 100% !important;
                            min-height: 250px;

                            .screen_box {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                .display_image {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    margin-bottom: 70px;

                                    img {
                                        position: relative;
                                        width: 450px;
                                        display: flex;
                                    }
                                }

                                .seat_sections {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    .section {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 40px;

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }

                                        .sec_name {
                                            position: relative;
                                            width: 100%;
                                            padding-bottom: 15px;
                                            border-bottom: 1px solid #E1E0EA;
                                            display: flex;
                                            align-items: center;

                                            h5 {
                                                position: relative;
                                                font-family: 'Lemonada', cursive;
                                                font-size: 16px;
                                                line-height: 1;
                                                font-weight: 500;
                                                color: #964B00;
                                            }

                                            p {
                                                position: relative;
                                                margin-left: 12px;
                                                font-size: 15px;
                                                color: #555;
                                            }
                                        }

                                        .sec_seat_rows {
                                            position: relative;
                                            width: 100%;
                                            margin-top: 30px;
                                            display: flex;
                                            flex-direction: column-reverse;

                                            .seat_row {
                                                position: relative;
                                                width: 100%;
                                                padding: 0px 60px;
                                                margin-top: 15px;

                                                &:last-of-type {
                                                    margin-top: 0;
                                                }

                                                .seats {
                                                    position: relative;
                                                    width: 100%;
                                                    display: flex;
                                                    flex-direction: row-reverse;
                                                    align-items: center;

                                                    li {
                                                        position: relative;
                                                        list-style: none;
                                                        width: 40px;
                                                        cursor: pointer;
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        color: #555;

                                                        i {
                                                            position: relative;
                                                            font-size: 15px;
                                                        }

                                                        p {
                                                            position: relative;
                                                            margin-top: 5px;
                                                            font-size: 12px;
                                                            line-height: 1;
                                                        }

                                                        &.online_booked {
                                                            color: #02C0FF;
                                                            pointer-events: none;
                                                        }

                                                        &.offline_booked {
                                                            color: #B57EDC;
                                                            pointer-events: none;
                                                        }
                                                    }
                                                }

                                                .index {
                                                    position: absolute;
                                                    top: 30%;
                                                    right: 5px;
                                                    transform: translateY(-50%);
                                                    font-size: 17px;
                                                    font-weight: 500;
                                                    color: #FC6736;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
            }

            .indicator_sec {
                position: relative;
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .indications {
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    li {
                        position: relative;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        margin-right: 40px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.online {
                            color: #02C0FF;
                        }

                        &.offline {
                            color: #B57EDC;
                        }

                        i {
                            font-size: 16px;
                        }

                        p {
                            font-size: 15px;
                            font-weight: 500;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
`;