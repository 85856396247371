import { useEffect, useState } from "react";
import { SeatReportWrapper } from "../Styles/Report-Style";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import axios from "axios";
import { Fetch_Seats_URL } from "../API/Api";

import 'swiper/css';
import 'swiper/css/free-mode';



function SeatReport({theater, bookedSeats, selectedMovie, selectedScreen}) {

    const [showSeatLayout, setShowSeatLayout] = useState(false);
    const [seatsData, setSeatsData] = useState([]);

    useEffect(() => {
        if(selectedMovie) {
            setShowSeatLayout(true);
        } else {
            setShowSeatLayout(false);
        }
    }, [selectedMovie]);

    useEffect(() => {
        axios.get(`${Fetch_Seats_URL}?id=${selectedScreen}`)
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                setSeatsData(res.data.seatData);
            }
        })
        .catch(err => {
            console.log(err);
            setSeatsData([]);
        })
    }, [selectedScreen]);

    const indexToAlphabet = (index) => {
        let letters = '';
        while (index >= 0) {
            letters = String.fromCharCode((index % 26) + 65) + letters;
            index = Math.floor(index / 26) - 1;
        }
        return letters;
    };

    const parseSeatsString = (seatsString) => {
        return seatsString.split(',').map(seat => parseInt(seat.trim(), 10));
    };

    const parseBookedSeatsString = (bookedString) => {
        return bookedString ? bookedString.split(',').map(seat => seat.trim()) : [];
    };

    const getOnlineBookedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.online_bookings) : [];
    };

    const getOfflineBookedSeatsForSection = (sectionName) => {
        if (!Array.isArray(bookedSeats)) return [];
        const section = bookedSeats.find(section => section.section === sectionName);
        return section ? parseBookedSeatsString(section.offline_bookings) : [];
    };

    return(
        <>
            <SeatReportWrapper>
                <div className="layout_inner">
                    <div className="inner_content">
                        <div className="seats_layout">
                            <div className="scroll_sec">
                                <Swiper
                                    freeMode={true}
                                    slidesPerView={'auto'}
                                    modules={[FreeMode]}
                                    className="mySwiper"
                                    style={{ overflow: 'visible' }}
                                >
                                    <SwiperSlide>
                                        {
                                            showSeatLayout &&
                                            <div className="screen_box">
                                                <div className="display_image">
                                                    <img src="/images/Screen-Display.svg" alt="Display" />
                                                </div>
                                                <div className="seat_sections">
                                                    {
                                                        seatsData && seatsData.map((seatData, index) => {
                                                            const onlineBookedSeatsForSection = getOnlineBookedSeatsForSection(seatData.sec_name);
                                                            const offlineBookedSeatsForSection = getOfflineBookedSeatsForSection(seatData.sec_name);
                                                            return(
                                                                <div className="section" key={index}>
                                                                    <div className="sec_name">
                                                                        <h5>{seatData.sec_name}</h5>
                                                                        <p>( Rs. {seatData.price}/- )</p>
                                                                    </div>
                                                                    <div className="sec_seat_rows">
                                                                        {
                                                                            (seatData.rows).map((seats, idx) => {
                                                                                const gapSeats = parseSeatsString(seats.gap_seats || '');
                                                                                const gapAmounts = parseSeatsString(seats.gap_amounts || '');
                                                                                return(
                                                                                    <div className="seat_row" key={idx}>
                                                                                        <div className="seats">
                                                                                            {
                                                                                                Array.from({ length: seats.seats }, (_, i) => {
                                                                                                    const isGap = gapSeats.includes(i + 1);
                                                                                                    const gapIndex = gapSeats.indexOf(i + 1);
                                                                                                    const marginLeft = isGap && gapIndex !== -1 ? `${gapAmounts[gapIndex] * 40}px` : '0';
                                                                                                    const seatNumber = `${indexToAlphabet(idx)}-${i + 1}`;
                                                                                                    const onlineBooked = onlineBookedSeatsForSection.includes(seatNumber);
                                                                                                    const offlineBooked = offlineBookedSeatsForSection.includes(seatNumber);
                                                                                                    return(
                                                                                                        <li key={i}
                                                                                                            className={`${onlineBooked ? 'online_booked' : ''} ${offlineBooked ? 'offline_booked' : ''}`}
                                                                                                            style={i === 0 ? { marginRight: `${seats.starting * 40}px`, marginLeft } : { marginLeft }}>
                                                                                                            <i className="fa-solid fa-couch"></i>
                                                                                                            <p>{i + 1}</p>
                                                                                                        </li>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        <span className="index">{indexToAlphabet(idx)}</span>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                        {
                            showSeatLayout && 
                            <div className="indicator_sec">
                                <div className="indications">
                                    <li className="online">
                                        <i className="fa-solid fa-couch"></i>
                                        <p>Online Booking</p>
                                    </li>
                                    <li className="offline">
                                        <i className="fa-solid fa-couch"></i>
                                        <p>Offline Booking</p>
                                    </li>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </SeatReportWrapper>
        </>
    );
}


export default SeatReport;